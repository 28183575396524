@import '.././_scss-variables.scss';

.metric-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;
  padding: 0px 0px 24px 0px;

  .metric-object,
  .secondary-metric-object {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 0.25em;
  }

  .secondary-metric,
  .secondary-metric-sub-label {
    color: var(--Schemes-On-Primary-Container, #3b0716);
    font-family: Roboto;
    font-size: 12px;
  }

  .metric {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: fit-content;
    padding: 1em;
    border: 1px solid $light-gray;
    border-radius: 10px;
    background-color: $light-gray;
  }s

  .metric-label {
    color: black;
    font-family: Roboto;
    font-size: 14px;
  }

  .metric-value {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    color: var(--Base-Shade-100, #000);
    /* Simple Rose/headline/medium */
    font-family: Roboto;
    font-size: 24px;
    font-weight: 400;
  }

  .metric-sub-label {
    color: var(--Base-Shade-100, #000);
    /* Simple Rose/label/small */
    font-family: Roboto;
    font-size: 11px;
  }
}
