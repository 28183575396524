@import '.././_scss-variables.scss';

.strategic-model-page {
  display: flex;
  flex-direction: column;
  padding: 0px 24px;

  .MuiBox-root {
    border-color: var(--m3-sys-light-surface-container-highest);
    padding-top: 5px;
  }

  .vendor-to-plant-panel,
  .model-assumptions-panel {
    display: flex;
    flex-direction: column;
    min-height: 300px !important;
    max-height: 70vh;
    width: 100%;
    position: relative;
  }

  .strategic-model-export-button,
  .data-validations-export-button {
    @include action-button;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .strategic-model-header-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 24px 24px 24px;

    .strategic-model-page-title {
      @include simplerose-display-small;
    }

    .right-align-header-items {
      display: flex;
      flex-direction: row;
      gap: 24px;
      height: 40px;
      justify-content: flex-end;

      .last-updated {
        @include informative-message-text;
      }

      .refresh-strategic-model-button {
        @include action-button;
      }
    }
  }

  .MuiTabs-flexContainer {
    width: 100%;
    justify-content: space-between;

    .MuiTab-root.Mui-selected {
      color: $simple-rose-pink;
    }

    .MuiTab-root {
      @include tab-label;
      padding-right: 40px;
      padding-left: 40px;
    }

    .vendor-to-plant-tab {
      width: 100%;
      text-align: center;
    }

    .model-assumptions-tab {
      width: 100%;
      text-align: center;
    }

    .model-assumptions-tab-label {
      display: flex;
      flex-direction: row;
      align-content: center;
    }

    .model-assumptions-tab-label-icon {
      padding-left: 0.75em;
      padding-right: 0.25em;
      font-size: 22px;
    }
  }

  .strategic-model-content-container {
    padding: 0px 24px;
  }

  //indicator bar beneath selected tab
  .MuiTabs-indicator {
    background-color: $simple-rose-pink !important;
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 5;

    .loading-message {
      @include loading-message;
    }

    .loading-indicator {
      @include loading-indicator;
    }
  }

  .accordion-panel {
    box-shadow: none;
    border-bottom: 1px solid $medium-light-gray;
    border-radius: 0px !important;

    .accordion-panel-content {
      padding-top: 0px;
      .metric-list {
        padding-bottom: 10px;
      }
    }
    .MuiAccordionDetails-root {
      border-top: 1px solid $medium-light-gray;
      padding-left: 0px;
    }
    .MuiAccordionSummary-root {
      min-height: auto;
    }
  }
  .global-metrics {
    margin-left: 24px;
  }
  .chart-container,
  .global-metrics,
  .metrics-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .global-metrics,
  .metrics-container {
    gap: 19px;
  }
  .chart-container {
    gap: 1em;
    & > * {
      flex: 1;
    }
  }
}
