@import '.././_scss-variables.scss';

.sortable-icon-container {
  display: flex;
  flex-direction: column;

  .sortable-icon {
    font-size: 24px;
    margin: -8px;
  }

  .black {
    color: $dark-gray;
  }
  .gray {
    color: $medium-light-gray;
  }
  .pink {
    color: $simple-rose-pink;
  }
}
