@import '.././_scss-variables.scss';

.data-grid-container {
  height: 550px;
  width: 100%;
  .data-grid {
    .data-grid-cell {
      align-items: flex-start;
      border-bottom: none;
      padding-left: 0px;
      padding-right: 0px;
      &:focus-within {
        outline: none;
      }
    }
    .data-grid-header {
      .MuiDataGrid-columnHeaderTitle {
        line-height: 1.0;
        text-align: left;
        white-space: normal;
      }
      .MuiDataGrid-iconButtonContainer {
        margin-left: 15px;
        width: fit-content;
        visibility: visible;
      }
      &:focus-within {
        outline: none;
      }
    }
    .data-grid-row {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .data-grid-row--even {
      background-color: inherit;
      &:hover {
        background-color: $simple-rose-pink;
        color: white;
      }
    }
    .data-grid-row--odd {
      background-color: $light-gray;
      &:hover {
        background-color: $simple-rose-pink;
        color: white;
      }
    }
    .MuiDataGrid-columnHeader--sortable {
      &:hover {
        color: $medium-gray;
      }
    }
    .MuiDataGrid-columnHeader--sorted {
      &:hover {
        color: inherit;
      }
    }
    .MuiDataGrid-columnHeaders {
      position: sticky;
    }
  }
}
