@import '.././_scss-variables.scss';
/*Header Styles*/
.header {
  display: flex;
  background-color: $simple-rose-pink;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  position: sticky;
  top: 0;
  z-index: 9999;
  width: 100%;
  font-size: large;
  font-weight: 400;
  height: 3.5em;
  color: white;

  /*header links */
  a {
    padding: 5px;
    color: white;
    text-decoration: none;
    font-variant: small-caps;
    font-family: Roboto;
    font-size: 21px;
  }

  a:hover {
    color: #F9CA92;
  }

  .header-user-profile-picture {
    border-radius: 50%;
  }
}

/* Style the logo link - set the same value of line-height and font-size as the other links to prevent the header from growing when the font increases 
  https://developer.mozilla.org/en-US/docs/Web/CSS/line-height*/
.header a.logo {
  padding-left: 15px;
  padding-right: 15px;
}

/*style the image within the logo link*/
.header-logo {
  padding-top: 5px;
  max-height: 2em;
  padding-right: 1em;
  margin-left: 15px;
}

.header .active {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: white;
  color: white;
  font-weight: 400;
}

.right-aligned-header-items {
  display: flex;
  align-content: flex-end;
  margin-left: auto;
  margin-right: 15px;
}

.header-iic-logo {
  width: 40px;
  height: 50px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 2px;
}