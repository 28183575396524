@import '.././_scss-variables.scss';
.vendor-list-container {
  display: flex;
  flex-direction: column;

  .iic-logo {
    width: 150px;
  }

  .vendor-loading-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .greeting-message {
      @include loading-message;
      margin-top: 0.5em;
    }

    .loading-indicator {
      @include loading-indicator;
    }
  }

  .vendor-container {
    align-self: center;
    display: flex;
    flex-direction: column;
    .vendor-information-container {
      .vendor-select-container {
        width: 450px;
      }
      .vendor-name {
        font-weight: 400;
        font-size: smaller;
        margin-top: 2.5em;
        user-select: all;
      }
    }
    .strategic-recommendation-chip {
      @include m3-label-large;
      align-self: center;
      background-color: var(--m3-sys-light-secondary-container);
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      font-weight: 500;
      margin-top: 0.5em;
      padding: 0.5em 1em 0.5em 1em;
      margin-top: 0.5em;
      width: fit-content;
    }
  }

  .view-recommendations-container {
    align-self: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100vw;
    .merchandise {
      padding-bottom: 40px;
      & > span {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.334;
        letter-spacing: 0em;
        text-align: center;
        border: solid;
        padding: 10px;
        border-color: #eeeeee;
        border-width: medium;
      }
    }
    & > span {
      padding-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      vertical-align: bottom;
    }
  }

  .show-criteria-selector {
    @media (min-width: 1200px) {
      align-self: center;
    }
    @media (max-width: 1200px) {
      bottom: 0;
      left: 0;
      padding-top: 15px;
      position: fixed;
      width: 100vw;
      z-index: 1;
    }
  }
  .hide-criteria-selector {
    align-self: center;
  }
  .show-criteria-selector,
  .hide-criteria-selector {
    .MuiFormGroup-root {
      align-items: center;
      .MuiFormControlLabel-root{
        background-color: var(--m3-sys-light-secondary-container);
        padding-right: 1em;
      }
    }
  }
  .criteria-container {
    padding: 0px 25px 25px 25px;
  }

}
