@import '.././_scss-variables.scss';

.side-navigation,
.side-navigation-expanded {
	cursor: pointer;
	flex-shrink: 0;
	transition: width 0.3s ease-in-out;
	.side-navigation-paper,
	.side-navigation-paper-expanded {
		background-color: white;
		box-shadow: 0px 60px 21px 0px rgba(0, 0, 0, 0.25);
		overflow-x: hidden;
		.side-navigation-list-container {
			height: 100%;
			margin-top: 80px;
			overflow: auto;
			.side-navigation-button,
			.side-navigation-active-button,
			.side-navigation-expanded-button {
				height: 40px;
				margin-bottom: 20px;
				margin-top: 10px;
				.side-navigation-icon,
				.side-navigation-active-icon {
					align-items: center;
					border-radius: 20%;
					color: #003F5C;
					display: inline-flex;
					justify-content: center;
					min-height: 40px;
					min-width: 40px;
				}
				.side-navigation-active-icon {
					background-color: $simple-rose-pink;
					color: white;
					& > svg {
						fill: white;
						& > path {
							fill: white;
						}
					}
				}
				.side-navigation-text,
				.side-navigation-active-text {
					align-items: center;
					border-radius: 10%;
					color: #003F5C;
					display: inline-flex;
					justify-content: left;
					margin-left: 5px;
				}
				.side-navigation-active-text {
					color: white;
					min-height: 40px;
				}
			}
			.side-navigation-active-button {
				background-color: $simple-rose-pink;
			}
			.side-navigation-expanded-button {
				padding-right: 50px;
			}
		}
	}
}
