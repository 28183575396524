@import '.././_scss-variables.scss';

.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  .drop-zone {
    width: 350px;
    height: 150px;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    border: 3px dashed $light-gray;
    border-radius: 15px;
    display: flex;
    flex-direction: column;

    .file-upload-icon {
      width: 4em;
      height: 4em;
      align-self: center;
    }
    // hide default 'no file chosen' text for input element
    .file-input {
      opacity: 0;
    }

    .upload-instructions {
      text-align: center;
      /* Simple Rose/title/medium */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.15px;
    }
    .file-upload-helper-text {
      color: $medium-gray;
      font-size: small;
      margin-top: 12px;
    }
  }

  .file-upload-icon {
    font-size: 0.87rem;
    margin-top: 10px;
    color: #bbcada;
  }

  .overlay {
    @include overlay;
  }

  .loading-container {
    z-index: 9999;
    position: fixed;
    top: 30%;
    left: 50%;

    img.loading-indicator {
      height: 8em;
      width: 8em;
    }
    .uploading-message {
      margin-top: 1em;
      font-family: 'Roboto';
      font-size: 1.5em;
    }
  }

  ::file-selector-button {
    display: none;
  }

  .submit-button {
    background-color: $simple-rose-pink;
    color: white;
    padding: 12px;
    width: 110px;
    margin-top: 24px;
  }

  /* prettier-ignore */
}

//styling for upload alert
.bold-alert {
  color: $simple-rose-pink;
  font-size: 24px;
}

.green-button-alert {
  background-color: green !important;
}

.gray-button-alert {
  background-color: gray !important;
}

.medium-text-alert {
  padding-top: 40px;
  font-size: large;
  font-weight: 400;
}
