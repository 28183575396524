@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

$simple-rose-pink: #da1c5c;
$light-pink: #f0a4be;
$dark-pink: #6d0f2e;
$dark-gray: rgb(49, 49, 49);
$medium-gray: rgb(88, 85, 85);
$moderate-gray: rgb(130, 130, 130);
$medium-light-gray: rgb(170, 170, 170);
$moderate-light-gray: rgb(200, 200, 200);
$light-gray: rgb(230, 230, 230);
$light-blue: rgb(173, 216, 230);

@mixin m3-label-large {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

@mixin overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $light-gray;
  opacity: 80%;
  z-index: 3; /* below the loading indicator */
}

@mixin loading-indicator {
  height: 5em;
  width: 5em;
  align-self: center;
  padding-top: 1em;
  z-index: 9999;
}

@mixin loading-message {
  margin-top: 10vh;
  font-size: x-large;
}

@mixin loading-spinner {
  width: 100px !important; /* Adjust the size of the spinner */
  height: 100px !important; /* Adjust the size of the spinner */
  color: $simple-rose-pink;
}

// Typography
@mixin tab-label {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  text-transform: none;
  color: var(--m3-sys-light-on-surface-variant);
}

@mixin informative-message-text {
  color: var(--Simple-Rose-ref-neutral-variant-neutral-variant0, #000);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.26px;
}

@mixin simplerose-display-small {
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 122.222% */
}

@mixin action-button {
  border-radius: 24px;
  width: fit-content;
  padding: 10px 60px;
  @include m3-label-large;
  background-color: $simple-rose-pink;
  color: white;
  box-shadow: none;
  &:hover {
    background-color: $dark-pink;
  }
}

:root {
  /* fonts */
  --m3-body-medium: Roboto;
  --font-inter: Inter;

  /* font sizes */
  --m3-body-small-size: 12px;
  --m3-body-large-size: 16px;
  --m3-body-medium-size: 14px;

  /* Colors */
  --color-white: #fff;
  --m3-sys-light-surface-container-highest: #f0dee0;
  --m3-sys-light-secondary-container: #ffd9de;
  --m3-sys-light-outline: #79747e;
  --outline-12: rgba(0, 0, 0, 0.12);
  --second-text-color: #737373;
  --m3-sys-light-surface: #fef7ff;
  --m3-sys-light-on-surface-variant: #49454f;
  --color-black: #000;
  --color-lavender: #e6e4f0;
  --color-mediumseagreen: #00b884;
  --color-royalblue: #5577ff;

  /* Gaps */
  --gap-xs: 12px;
  --gap-5xs: 8px;
  --gap-3xs: 10px;

  /* Paddings */
  --padding-11xs: 2px;
  --padding-5xs: 8px;
  --padding-9xs: 4px;
  --padding-xs: 12px;
  --padding-6xs: 7px;
  --padding-base: 16px;
  --padding-5xl: 24px;

  /* Border radiuses */
  --br-81xl: 100px;
  --br-5xl: 24px;
  --br-9xs: 4px;
  --br-3xs: 10px;
  --br-11xs: 2px;
  --br-8xs: 5px;
  --br-base: 16px;
}
