.vendor-select-wrapper .placeholder-sub-text {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 24px;
}

.vendor-select-wrapper .placeholder-text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 0;
}

.vendor-select-wrapper .label-sub-text {
  position: relative;
  line-height: 16px;
}

.vendor-select-wrapper .label-text {
  margin: 0 !important;
  position: absolute;
  top: -12px;
  left: -4px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-9xs);
  z-index: 1;
  font-size: var(--m3-body-small-size);
  color: var(--color-black);
  font-family: var(--m3-body-medium);
}

.vendor-select-wrapper .content,
.vendor-select-wrapper .state-layer,
.vendor-select-wrapper .text-field-sub {
  flex: 1;
  display: flex;
  align-items: flex-start;
}

.vendor-select-wrapper .content {
  height: 48px;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-9xs) 0;
  box-sizing: border-box;
  position: relative;
}

.vendor-select-wrapper .state-layer,
.vendor-select-wrapper .text-field-sub {
  align-self: stretch;
  justify-content: flex-start;
}

.vendor-select-wrapper .state-layer {
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  flex-direction: row;
  padding: var(--padding-9xs) 0 var(--padding-9xs) var(--padding-base);
}

.vendor-select-wrapper .text-field-sub {
  border-radius: var(--br-9xs);
  border: 1px solid var(--m3-sys-light-outline);
  flex-direction: column;
  z-index: 0;
}

.vendor-select-wrapper .text-field,
.vendor-select-wrapper {
  width: 483px;
  height: 56px;
  font-size: var(--m3-body-medium-size);
  color: var(--color-black);
  font-family: var(--m3-body-medium);
  align-self: center;
  padding-top: 10px;
}

.vendor-select-wrapper .text-field {
  top: 0;
  left: 0;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.vendor-select-wrapper {
  top: 297px;
  left: 496px;
  text-align: left;
  font-size: var(--m3-body-large-size);
  color: var(--m3-sys-light-on-surface-variant);
}

// Needed to remove border around React Select since React Select puts the border on the element itself
.vendor-select-wrapper .css-13cymwt-control {
  border-width: 0px !important;
}

// Needed to remove the vertical line to the left of the arrow on React Select
.vendor-select-wrapper .css-1u9des2-indicatorSeparator {
  width: 0px !important;
}
