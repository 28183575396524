@import '.././_scss-variables.scss';

.data-management-container {
  display: flex;
  flex-direction: row;

  //override css from app.css - TODO: remove from app css and localize
  .MuiPaper-elevation.MuiPaper-root.MuiPaper-rounded {
    min-height: 0px;
  }

  .column-1 {
    margin-left: 1em;
    display: flex;
    width: 200px;
    flex-direction: column;
    align-items: flex-start;
  }

  .column-2 {
    width: 80vw;
    height: 100vh;
    display: flex;

    .file-content-container-skeleton {
      height: 500px;
      margin-left: 24px;
      width: 100%;
    }
    .file-content-container {
      display: flex;
      flex-direction: column;
      padding-left: 24px;
      min-height: 300px !important;
      max-height: 80vh;
      width: 100%;

      .content-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
        width: 100%;
        text-align: center;
        justify-content: center;
        background-color: $light-gray;

        h4 {
          text-align: center;
          flex: 1;
        }
      }

      .last-updated {
        margin-left: auto;
        display: inline-block;
        font-size: 0.9em;
        margin-right: 20px;
      }
      .button-container {
        display: flex;
        justify-content: center;
        gap: 0.5em;
        .upload-button,
        .download-button {
          display: inline-flex;
          height: 40px;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          align-self: center;
          border-radius: 100px;
          width: fit-content;
          margin-top: 12px;
          box-shadow: none;
        }

        .upload-button {
          background: $simple-rose-pink;
        }
        .upload-button:hover {
          background: $light-pink;
        }

        .download-button {
          background-color: white;
          font-family: 'Roboto';
          color: $medium-gray;
          border: 1px solid $medium-light-gray;
        }

        .download-button:hover,
        .download-button:active {
          background-color: $light-gray;
          color: $medium-light-gray;
        }

        .overlay {
          @include overlay;
        }

        .loading-container {
          z-index: 9999;
          position: fixed;
          top: 30%;
          left: 45%;

          img.loading-indicator {
            height: 8em;
            width: 8em;
          }
          .uploading-message {
            margin-top: 1em;
            font-family: 'Roboto';
            font-size: 1.5em;
          }
        }
      }

      .data-title-message {
        justify-self: center;
        margin-top: 24px;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        letter-spacing: 0.15px;
      }
    }
  }
}
