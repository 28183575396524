@import '.././_scss-variables.scss';

.card-container {
  margin-top: -10px;
  position: relative;
  z-index: -1;
  .MuiPaper-root {
    border: 1px solid $moderate-light-gray;
    box-shadow: 5px;
    width: 298px;
    .card-price {
      align-items: center;
      background-color: white;
      border-top: 2px solid $moderate-light-gray;
      border-bottom: 3px solid $moderate-light-gray;
      border-radius: 50%;
      box-shadow: 0 0 0 1px white;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      height: 75px;
      left: 10px;
      padding: 0px;
      position: absolute;
      top: -5px;
      width: 75px;
      .dollar-sign {
        position: relative;
        top: -0.2em;
        font-size: 70%;
      }
    }
    .MuiCardHeader-root {
      .card-title-container {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin-left: 80px;
        .card-title {
          font-size: 1.0rem;
          text-align: left;
          min-width: 90px;
        }
        .card-subheader {
          border: 2px solid;
          border-color: $moderate-light-gray;
          border-radius: 7px;
          font-size: 0.7rem;
          margin-left: 30px;
          padding: 4px 8px;
        }
      }
    }
    .MuiCardContent-root {
      border-top: 2px solid $moderate-light-gray;
      box-shadow: 5px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .card-description-line-container {
        display: inline-flex;
        flex-direction: column;
        margin-top: 10px;
        min-height: 50px;
        .card-description-label {
          text-align: left;
        }
        .card-description-value {
          text-align: left;
        }
      }
      .card-description-line-top-row,
      .card-description-line-other-row {
        margin-right: 5px;
        width: 107px;
      }
    }
  }
  .recommended {
    background-color: var(--m3-sys-light-secondary-container);
    outline: solid;
    outline-color: $simple-rose-pink;
    outline-width: medium;
    .card-price {
      background-color: $simple-rose-pink;
      border: 3px solid white;
      box-shadow: 0 0 0 1px $simple-rose-pink;
      color: white;
    }
    .MuiCardHeader-root {
      background-color: $simple-rose-pink;
      .card-title-container {
        .card-title {
          color: white;
        }
        .card-subheader {
          background-color: var(--m3-sys-light-secondary-container);
          border-color: var(--m3-sys-light-secondary-container);
        }
      }
    }
  }
  //restricted or at capacity
  .restricted {
    color: $moderate-gray;
    .MuiCardHeader-root {
      color: $moderate-gray;
    }
  }
}
