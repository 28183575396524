@import '.././_scss-variables.scss';

.data-selection-container {
  display: flex;
  flex-direction: column;
  min-width: fit-content;

  .data-selection-button {
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    border: none;
    border-color: $light-gray;
    background-color: white;
    color: black;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
    text-transform: none;
    box-shadow: none;
    &:hover {
      background-color: $light-gray;
      border: none;
      box-shadow: none;
    }
    font-size: 1em;
    font-weight: 200;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
  .data-selection-button.selected.MuiButton-root {
    background-color: $light-gray;
    border-radius: none;
  }

  .enabled & .data-selection-button {
    background-color: $light-gray;
  }
}
