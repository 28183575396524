@import './_scss-variables.scss';

body {
  background-color: white;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 36px;
}

.app-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-left: 80px;
}

.faded {
  opacity: 0.5;
}

.hr {
  margin-top: 1.5em;
}

.summary-view-table {
  align-self: center;
  margin-bottom: 5em;
}

.greeting-message {
  font-size: x-large;
}

.login-button {
  border-radius: 0.1rem;
  min-width: 100px;
  border: 1px solid #da1c5c;
  background-color: #da1c5c;
  color: #fff;
  line-height: 1.5;
  cursor: pointer;
}

.login-message {
  padding-top: 30px;
}

.recommendation-engine-header {
  font-size: 32px;
  line-height: 40px;
  color: var(--color-black);
  font-family: var(--m3-body-medium);
}

