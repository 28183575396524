@import '.././_scss-variables.scss';

.operational-model-page-container {
  display: flex;
  flex-direction: column;
  padding: 0px 24px;

  .operational-model-header-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 24px 0px 0px;

    .recommendation-engine-header {
      @include simplerose-display-small;
    }

    .right-align-header-items {
      display: flex;
      flex-direction: row;
      gap: 24px;
      justify-content: flex-end;

      .last-updated {
        @include informative-message-text;
      }

      .run-operational-model-button {
        border-radius: 24px;
        width: fit-content;
        padding: 10px 60px;
        @include m3-label-large;
        background-color: $simple-rose-pink;
        box-shadow: none;
      }
      .run-operational-model-button:hover {
        background-color: $dark-pink;
      }
    }
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 5;

    .loading-message {
      @include loading-message;
    }

    .loading-indicator {
      @include loading-indicator;
    }
  }
}
