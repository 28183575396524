@import '.././_scss-variables.scss';

.plant-inventory-level-chart {
  border: 1px solid $light-gray;
  border-radius: 20px;
  margin-bottom: 48px;
  max-width: 45vw;
  padding: 1em;
  .MuiBarElement-root {
    opacity: 0.8;
  }
  .MuiChartsAxis-label {
    transform: translateX(-40px)
  }
}
